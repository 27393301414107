export const ROUTES = {
  ACCOUNT_SETTINGS: "/my-account",
  HOME: "/",
  MEMBER_SIGNUP: "/member-signup",
  PROSPECT_SIGNUP: "/prospect-signup",
  STUDIOS: "/studios",
  STUDIO_SIGNUP: "/studio-signup",
  PROSPECT_BOOK: "/prospect-book",
};

export const SITE_TEXT_CONFIG = {
  STUDIO_TYPE: "Nike Studio",
  STUDIO_TYPES: "Nike Studios",
  SITE_NAME: "Nike Studios",
  SHORT_SITE_NAME: "Nike",
  PRIVACY_URL: `https://agreementservice.svs.nike.com/rest/agreement?agreementType=privacyPolicy&uxId=nikeStudios&country={{COUNTRY_CODE}}&language=en&requestType=redirect`,
  TERMS_URL: `https://agreementservice.svs.nike.com/rest/agreement?agreementType=termsOfUse&uxId=nikeStudios&country={{COUNTRY_CODE}}&language=en&requestType=redirect`,
  PROSPECT_CONSENT: `<p>By clicking "{{SUBMIT_BUTTON_TEXT}}" you consent to receive recurring, periodic promotional, personalized marketing text messages sent by automated technology from Nike concerning Nike Studios openings, offers, and updates to the phone number you provided. You also consent to receive marketing email messages and marketing phone calls to the phone number you provided. You can opt-out from text messaging by replying "STOP" to any text message. You can opt-out from emails by unsubscribing. You can request not to be called by phone if you receive a phone call. Message frequency varies. Message & data rates may apply. View the Nike <a href="{{PRIVACY_POLICY_URL}}" target="_blank">Privacy Policy</a> and <a href="{{TERMS_URL}}" target="_blank">Terms of Use</a> for more information.</p>`,
  MAIL_URL: "info@nikestudios.com",
};
